<template>
  <div class="wscn-http404-container">
    <div class="wscn-http404">
      <div class="pic-404">
        <img class="pic-404__parent" src="@/assets/404_3.png" alt="404" />
      </div>
      <div class="bullshit">
        <div class="bullshit__oops">500</div>
        <div class="bullshit__info">抱歉，服务器出了点小问题~</div>
        <div>
          <a-button type="primary" @click="backHome">返回首页</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
  },
  methods: {
    backHome () {
      this.$router.push({ path: '/WorkManagement' })
    },
  },
})
</script>

<style lang="scss" scoped>
.wscn-http404-container {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 56%;
}
.wscn-http404 {
  position: relative;
  width: 1160px;
  padding: 0 50px;
  overflow: hidden;
  .pic-404 {
    position: relative;
    float: left;
    width: 600px;
    overflow: hidden;
  }
  .bullshit {
    position: relative;
    float: left;
    width: 300px;
    overflow: hidden;
    .bullshit__oops {
      font-size: 60px;
    }
    .bullshit__info {
      color: #999;
      margin: 10px 0 ;
    }
    .ant-btn{
      background-color: #134CCF;
      margin-top: 30px;
    }
  }
}
</style>
